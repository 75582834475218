/* src/home.css */

/* Animation for .div-bg-one */
.icon-buttons {
  display: flex;
  gap: 10px; /* Space between the icons */
  justify-content: center; /* Center the icons */
  margin-top: 10px; /* Add some space above the icons */
}

@keyframes popup-one {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Animation for .div-bg-two */
@keyframes popup-two {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.div-bg-one {
  background: rgb(8,11,9);
  background: -moz-linear-gradient(90deg, rgba(8,11,9,1) 0%, rgba(33,40,21,1) 51%, rgba(3,4,3,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(8,11,9,1) 0%, rgba(33,40,21,1) 51%, rgba(3,4,3,1) 100%);
  background: linear-gradient(90deg, rgba(8,11,9,1) 0%, rgba(33,40,21,1) 51%, rgba(3,4,3,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#080b09",endColorstr="#030403",GradientType=1);

  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  
  margin: 5%;
  padding: 20px;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Animation */
  animation: popup-one 0.8s ease-out forwards;
}

.div-bg-two {
  background: rgb(12,15,13);
  background: -moz-linear-gradient(90deg, rgba(12,15,13,1) 0%, rgba(37,45,25,1) 51%, rgba(7,8,7,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(12,15,13,1) 0%, rgba(37,45,25,1) 51%, rgba(7,8,7,1) 100%);
  background: linear-gradient(90deg, rgba(12,15,13,1) 0%, rgba(37,45,25,1) 51%, rgba(7,8,7,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c0f0d",endColorstr="#070807",GradientType=1);

  border-radius: 8px;
  margin: 5%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Animation */
  animation: popup-two 0.8s ease-out forwards;
}

/* Additional styles for the elements within the divs */
#logo, #div-two-img {
  height: 20vh;
  border-radius: 50%;
}

.welcome-msg, .div-two-msg {
  color: white;
  text-align: center;
}

p {
  color: white;
  text-align: center;
  max-width: 600px;
}

/* Additional styling for IconButton */
.MuiIconButton-root {
  color: white; /* Make the icon white to match the theme */
}

.MuiSvgIcon-root {
  font-size: 2rem; /* Adjust the icon size if needed */
}

/* Countdown number styling */
.div-bg-two p {
  font-size: 2em;
  color: #f39c12; /* Example color */
  margin: 0;
  padding: 0;
  text-align: center;
  transition: all 0.2s ease; /* Smooth transition effect */
}

#addy {
  font-size: smaller;
}

#tax {
  font-size: small;
}

#div-two-video {
  border-radius: 50%;
}

/* home.css */

.div-bg-three {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust as needed */
  overflow: hidden;
}

#div-three-video {
  width: 100%;
  height: auto;
}

.swiper-button-next, .swiper-button-prev {
  color: #fff; /* Customize button color */
}

/* home.css */

/* Ensure videos are responsive */
video {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; /* Limit max height for mobile */
  object-fit: cover; /* Cover the area without distortion */
}

